.bs-stepper-validated {
    background-color: $primary !important;
}

.bs-stepper-invalidated {
    background-color: red !important;
}

.bs-label-validated {
    color: $primary !important;
}

.bs-label-invalidated {
    color: red !important;
}

.bs-active .bs-stepper-circle {
    background-color: royalblue;
}

.bs-active .bs-stepper-label {
    color: royalblue
}

.stepper-disabled {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #6c757d;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: none;
    border-radius: .25rem;
    transition: background-color .15s ease-out,color .15s ease-out;
}
