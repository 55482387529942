ins {
    color: black;
    background: #bbffbb;
}

del {
    color: black;
    background: #ffbbbb;
}

.ck-content {
    display: block !important;
}

h3 {
    color: $primary;
}

.bg-brand {
    background-color: $maestro;
}

.right-0 {
    right: 0;
}

.sidebar .nav-link.active {
    background-color: $primary !important;
}

.pointer {
    cursor: pointer;
}

.focus-success {
    color: #5c6873;
    background-color: #fff;
    border-color: #a9ddd6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 184, 170, 0.25);
}

.focus-danger {
    color: red;
    background-color: #fff;
    border-color: red;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(184, 78, 78, 0.25);
}

.swal2-title {
    font-size: 1rem !important;
    margin-bottom: 1.5rem !important;
}

.swal2-content {
    font-size: 0.90rem !important;
}

.ck-sticky-panel__content,
.ck-sticky-panel,
.ck-editor__top,
.ck-toolbar {
    width: 100% !important;
}

html {
    overflow-y: hidden;
}

.dropdown-menu {
    z-index: 9000 !important;
}

ngb-datepicker-navigation {
    width: 100%
}

ngx-editor, .form-editor {
    width: 100%;
}

ngx-editor-menu {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    div {
        // display: unset !important;
        flex-wrap: unset !important;
        width: unset !important;
    }
}

.NgxEditor__Wrapper {
    border: 1px solid rgba(0,0,0,.2) !important;
    border-radius: 4px;
}

.NgxEditor__Seperator {
    margin: 0 !important;
    display: unset !important;
    flex-wrap: unset !important;
    width: unset !important;
}

.NgxEditor__Dropdown, .NgxEditor__MenuItem {
    position: unset !important;
}

.NgxEditor__MenuItem .NgxEditor__MenuItem--IconContainer {
    margin-bottom: 0 !important;
}

.NgxEditor__MenuBar {
    height: unset !important;
    margin-bottom: 0 !important;
    overflow-y: auto;
}

.NgxEditor__Dropdown--DropdownMenu {
    display: unset !important;
}

.NgxEditor__Dropdown--Text {
    margin-bottom: 0 !important;
}

.NgxEditor__Content, .NgxEditor__Popup {
    display: block !important;
}

.NgxEditor, .NgxEditor__Content {
    margin: 0 !important;
}

.ngx-form {
    width: 100% !important;
}

.NgxEditor__MenuItem--IconContainer {
    margin-bottom: 0 !important;
}

.multiselect-dropdown {
    span {
        white-space: nowrap;
        max-width: unset !important;
    }
}


.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.swal2-actions {
    z-index: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.maestro-background-checkerboard {
    background: repeating-conic-gradient(rgba(128, 128, 128, 0.25) 0% 25%, transparent 0% 50%) 
    50% / 20px 20px
}

.primary {
    color: $primary;
}
