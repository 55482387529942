.modal.dark {
    .modal-content {
        background-color: white;
        color: #2f353a;
    }
    .modal-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        font-weight: bold;
        .close {
            color: #2f353a;
            text-shadow: none;
        }
    }
    .modal-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
}

.modal-dam {
    .modal-dialog {
        max-width: 95%;
        height: 90%;

        .modal-body {
            height: 70vh;
            overflow: auto;

            .card-columns {
                column-count: 5;
            }
        }
    }
}
