/**
 * GENERAL
 */
@import 'customs/global';
@import 'customs/layout';

/**
 * COMPONENTS
 */
@import 'customs/breadcrumb';
@import 'customs/element-type-tabs';
@import 'customs/form-create';
@import 'customs/form-edit';
@import 'customs/form-fill';
@import 'customs/form-schema';
@import 'customs/sidebar';
@import 'customs/sidebar-crop';
@import 'customs/tables';
@import 'customs/project-dashboard';
@import 'customs/primeng';

/**
 * VENDOR CUSTOMIZATIONS
 */
@import 'customs/buttons';
@import 'customs/cards';
@import 'customs/labels';
@import 'customs/modals';
@import 'customs/ng-multiselect-dropdown';
@import 'customs/ngx-json-schema-form';
@import 'customs/tabs';
@import 'customs/bs-stepper';
@import 'customs/p-cascadeselect';
