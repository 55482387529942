#crop-sidebar {
  .slider.slider-horizontal {
    width: 100%;

    .slider-handle {
      background-image: none;
      box-shadow: none;
      border: none;
    }
  }
}
