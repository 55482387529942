.nav-tabs {
    border-bottom: 0px;
  .nav-item {
    .nav-link {
        border-top: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-left-width: 0;
        border-radius: 0;
        margin-bottom: 1px;
      &.active {
        
        color: $primary;
        

        &.fieldset {
          
          border-top: 2px solid #ccc;
          border-right: 2px solid #ccc;
          border-left: 2px solid #ccc;
          color: white;
          font-weight: bold;
          background-color: $primary;
        }
      }

      &:not(.active):hover {
          color: $primary;
          border-bottom:1px solid $primary;
      }
      label {
        margin-bottom: 0;
        cursor: pointer;
      }
    }
    &:first-child {
      .nav-link {
        border-left-width: 1px;
        border-top-left-radius: 0.25rem;
      }
    }
    &:last-child {
      .nav-link {
        border-top-right-radius: 0.25rem;
      }
    }
  }
}
