#form-create {
  .row.form-group {
    margin-bottom: 0;
  }

  .contener-fs {
    overflow: auto;
    border-top: 1px solid #c8ced3;
    padding: 0.75rem;

    > sf-form-element {
      > div {
        float: left;
        width: 90%;

        fieldset {
          > div {
            float: left;
            width: 30%;
            margin: 0 3.3% 0 0;

            .form-group {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .array-remove-button {
      float: right;
    }
  }

  .contener-fs:nth-child(odd) {
    background-color: #fff;
  }

  .contener-fs:nth-child(even) {
    background-color: #eee;
  }

  .contener-fs:first-child {
    border: none;
  }

  sf-button-widget,
  button.array-add-button {
    display: none;
  }
}
