button.p-panel-header-icon.p-panel-toggler.p-link:focus {
    box-shadow: 0 0 0 0.2rem $primary;
}

div.p-progressbar-value.ng-star-inserted {
  background: $primary;
}

.p-column-filter-overlay{
  width: 500px !important;
}