.card.card-footeronly,
.card.card-footeronly .card-footer {
    border-top: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-title {
    font-size: medium;
}

.card-thumb {
    // max-height: 7rem;
    // height: 7rem;
    // object-fit: scale-down;
    // object-position: center;
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-thumb-format{
    background-color:rgba(100, 100, 100, 0.5);
    color:white;
}

.card-columns {
    overflow: hidden;
    // @include media-breakpoint-only(xs) {
    @media (min-width: 34em) {
        column-count: 2;
        -webkit-column-count: 2;
        -moz-column-count: 2;
    }
    // @include media-breakpoint-only(sm) {
    @media (min-width: 48em) {
        column-count: 3;
        -webkit-column-count: 3;
        -moz-column-count: 3;
    }
    // @include media-breakpoint-only(md) {
    @media (min-width: 62em) {
        column-count: 4;
        -webkit-column-count: 4;
        -moz-column-count: 4;
    }
    // @include media-breakpoint-only(lg) {
    @media (min-width: 75em) {
        column-count: 5;
        -webkit-column-count: 5;
        -moz-column-count: 5;
    }
    // @include media-breakpoint-only(xl) {
    @media (min-width: 88em) {
        column-count: 6;
        -webkit-column-count: 6;
        -moz-column-count: 6;
    }
}

.card-body {
    min-height: 105px;
}

.card-body-img-fixed-height {
    min-height: 10rem; 
    max-height: 10rem;
    object-fit: contain;
}

// .tags {
//     height: 22px;
//     overflow: hidden;
// }
