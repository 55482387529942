.tableComponent {
    table {
        margin-bottom: 0;
        tr {
            th:last-child,
            td:last-child {
                .action-right {
                    text-align: right;
                }

                .component-action-button {
                    margin-top: -8px;
                    margin-bottom: -6px;
                }
            }
        }
        thead {
            tr {
                th {
                    border-top: 0;
                    padding-top: 0;
                }
            }
        }
    }
}

.tableOverlayPanelContainer .p-overlaypanel{
    z-index: 9999; // To display overlay in front off button
}

.tableOverlayPanelContainer .p-overlaypanel:before, .tableOverlayPanelContainer .p-overlaypanel:after{
    margin-left:10px; // small hack for the caret
}

.tableOverlayPanel .p-overlaypanel-close {
    background-color: $primary;
}

.p-overlaypanel{
    position: initial  !important;
}
