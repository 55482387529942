.sidebar {
  height: calc(100vh - 55px) !important;
  padding-bottom: 60px;

  .nav {
    .nav-item:first-child {
      margin-top: 10px;
    }
    .nav-item > span > .active {
      background-color: $maestro;
    }
    .nav-link:hover,
    .badge-primary {
      background-color: $maestro;
      color: white;
      transition: none;
    }

    .nav-link[aria-expanded='true']::before {
      transform: rotate(270deg);
    }
    .nav-dropdown.open {
      .nav-dropdown-items {
        overflow-y: inherit;
        max-height: none;
      }
      .nav-dropdown-item {
        padding-left: 30px;
      }
    }
  }

  .dropdown-menu {
    margin-top: 0;
  }

  .dropdown-menu .dropdown-toggle::after {
    vertical-align: middle;
    border-left: 4px solid;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
  }

  .dropdown-menu .dropdown .dropdown-menu {
    left: 100%;
    top: 0%;
    margin: 0 20px;
    border-width: 0;
  }

  .dropdown-menu > li a:hover,
  .dropdown-menu > li.show {
    color: white;
  }

  .dropdown-menu > li.show > a {
    color: white;
  }

  @media (min-width: 768px) {
    .dropdown-menu .dropdown .dropdown-menu {
      margin: 0;
      border-width: 1px;
    }
  }

  .angular-tree-component {
    padding: 0 10px 20px 10px;

    .tree-node {
      .node-wrapper {
        .toggle-children-wrapper {
          .toggle-children {
            background-image: url('data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+');
            transform: rotate(180deg);
          }

          &.toggle-children-wrapper-expanded {
            .toggle-children {
              transform: rotate(270deg);
            }
          }
        }

        .node-content-wrapper {
          width: 100%;

          &:hover {
            background: $maestro;
          }
        }
      }

      .tree-children {
        padding-left: 10px;
      }
    }
  }
}
