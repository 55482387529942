#form-edit {
  .fieldset-row {
    margin-bottom: 0;
    padding-right: 20px;

    .fieldset-container {
      max-height: 80vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0;

      .fieldset-header {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 100;
      }

      .contener-fs {
        overflow: auto;
        padding: 0.75rem;
        border: 1px solid rgb(196, 196, 196);
        border-top: 0;

        > sf-form-element {
          > div {
            float: left;
            width: 90%;
          }
        }

        .array-remove-button {
          float: right;
        }
      }

      .contener-fs:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.02);
      }

      .array-add-button {
        float: right;
      }
    }
  }
}
