.breadcrumb {
  top: 55px;

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #23282c;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .btn-primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      color: #fff !important;
      background-color: #40a093 !important;
      border-color: #40a093 !important;
    }
  }
  .btn-danger {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      color: #fff !important;
      background-color: #f64846 !important;
      border-color: #f64846 !important;
    }
  }
}
