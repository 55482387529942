.btn-primary-filter {
  color: #fff !important;
}
.btn-maestro-yellow{
    background-color: $maestro-yellow !important;
    border-color: $maestro-yellow !important;
    color : #333333 !important;
}

.btn-primary,
.btn-outline-primary.active{
    color: #fff !important;
}

.btn-primary:not([disabled]):hover,
.btn-primary:not([disabled]):focus {
  color: #fff !important;
  background-color: #40a093 !important;
  border-color: #40a093 !important;
}

.btn-warning:not([disabled]):hover,
.btn-warning:not([disabled]):focus {
    color: #fff !important;
    background-color: $warning !important;
    border-color: $warning !important;
}


.btn-outline-primary:not([disabled]):hover,
.btn-outline-primary:not([disabled]):focus {
  color: #fff !important;
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn[disabled] {
  cursor: not-allowed;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-sm {
    padding: 0 0.35rem;

}

.btn-sm-primary {
    color: #4eb8aa;
}

.btn-sm-danger {
    color: #f86c6b;
}

.btn-sm-primary:not([disabled]):hover,
.btn-sm-primary:not([disabled]):focus {
  color: #fff;
  background-color: #40a093;
  border-color: #40a093;
}

.btn-sm-danger:not([disabled]):hover,
.btn-sm-danger:not([disabled]):focus {
  color: #fff;
  background-color: #f86c6b;
  border-color: #f86c6b;
}

button.swal2-confirm.swal2-styled {
  background-color: $primary;
}

.pageNumberEdit {
  border: 1px solid $maestro;
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;

  button:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  fa-icon {
    color: $maestro;
  }
}

/* Used for PrimeNg PickList */
button.p-ripple.p-button.p-component.p-button-icon-only {
  background-color: $maestro !important;
  border-color: $maestro-darkened;
}