#formfill {
  form {
    label {
      font-weight: bold;
    }

    sf-button-widget {
      button {
        display: none;
      }
    }
  }
}
