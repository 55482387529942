.main {
  // min-height: calc(100vh - 55px);
  // overflow: auto;
  padding-top: 30px;

}

.app-header {
  background-color: $maestro;
  border-bottom: 0;

  .navbar-brand {
    width: 200px;
  }

  .navbar-nav {
    .nav-item {
      &:first-child {
        padding-left: 0 !important;
      }

      .nav-link {
        color: rgba(255, 255, 255, 0.7);
        font-weight: lighter;
        font-size: 16px;

        &:hover,
        &.active {
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}
