.card-export {
  .card-header,
  .card-footer {
    border-width: 1px;
    border-style: solid;
  }

  .card-body {
    border-width: 0 1px;
    border-style: solid;
  }

  .export-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    max-height: 58px;
    overflow-x: auto;
    position: relative;
  }

  .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .text-muted {
    color: inherit !important;
    opacity: 0.5;
  }
}
