.contener-fs {
    padding: 0.75rem 0;

    > sf-form-element {
        > div {
            float: left;
            width: 90%;
        }
    }
    /*.array-remove-button {
        float: right;
    }*/
}

.contener-fs:nth-child(even) {
    background-color: #fff;
}

.contener-fs:nth-child(odd) {
    background-color: #eee;
}

#type_field {
    .card-body {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .form-group {
        margin-bottom: 0 !important;
    }
    .contener-fs {
        border-top: 1px solid #c8ced3;

        > .col > sf-form-element {
            > div {
                fieldset {
                    > div {
                        display: inline-block;
                        margin-right: 0.5rem;
                    }
                    > div:nth-child(6),
                    > div:nth-child(7),
                    > div:nth-child(8) {
                        
                    }
                    //     label {
                    //         display: none;
                    //     }

                    //     .switch {
                    //         display: block;
                    //         margin-bottom: 0;
                    //     }
                    // }

                    // label[for*='Preview'] + div:before {
                    //     font-family: 'Font Awesome 5 Free';
                    //     content: '\f06e';
                    //     font-weight: 400;
                    // }

                    // label[for*='Translatable'] + div:before {
                    //     font-family: 'Font Awesome 5 Free';
                    //     content: '\f1ab';
                    //     font-weight: 900;
                    // }

                    // label[for*='Multiple'] + div:before {
                    //     font-family: 'Font Awesome 5 Free';
                    //     content: '\f0ae';
                    //     font-weight: 900;
                    //     margin-right: 2px;
                    // }
                }
            }
        }
    }
}

#fieldset_update {
    .card-body {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .form-group {
        margin-bottom: 0 !important;
    }

    .contener-fs {
        border-top: 1px solid #c8ced3;
        margin-left: -20px;
        margin-right: -20px;

        > .col > sf-form-element {
            > div {
                fieldset {
                    > div {
                        display: inline-block;
                        margin-right: 0.5rem;
                    }
                    > div:nth-child(4),
                    > div:nth-child(5) {
                        label {
                            display: none;
                        }

                        .switch {
                            display: block;
                            margin-bottom: 0;
                        }
                    }

                    label[for*='Translatable'] + div:before {
                        font-family: 'Font Awesome 5 Free';
                        content: '\f1ab';
                        font-weight: 900;
                        margin-right: 2px;
                    }

                    label[for*='Multiple'] + div:before {
                        font-family: 'Font Awesome 5 Free';
                        content: '\f0ae';
                        font-weight: 900;
                        margin-right: 2px;
                    }
                }
            }
        }
    }
}

#list_update {
    .card-body {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0;
    }

    .form-group {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .contener-fs {
        border-top: 1px solid #c8ced3;
        margin-left: -20px;
        margin-right: -20px;

        > .container-fields > sf-form-element {
            > div {
                fieldset {
                    > div {
                        .col-3 {
                            flex: 0 0 10%;
                            max-width: 10%;
                        }
                        .col-sm-9 {
                            flex: 0 0 90%;
                            max-width: 90%;
                        }
                    }
                }
            }
        }
    }
}

#form {
    .array-add-button {
        position: absolute;
        top: 0;
        right: 5px;
    }

    > .fieldset-row {
        padding-right: 20px;
        > .fieldset-container {
            padding: 0;
            > .fieldset-header {
                position: sticky;
                top: 0;
                left: 0;
                z-index: 100;
            }
            > .contener-fs {
                border: 1px solid rgb(196, 196, 196);
                border-top: 0;
            }
        }
    }
}

app-element-update-tab app-fieldset-widget div .input-group {
    width: 35vw !important;
}
